<template lang="pug">
main#upload-product.mt-3
  form-create-vehicle
</template>
<script>
import FormCreateVehicle from "@/components/product/FormCreateVehicle";

export default {
  name: "ProductCreate",
  components: {
    FormCreateVehicle,
  },
  data() {
    return {
      arrayCategoryClassification: [],
      currentComponent: "",
    };
  },
};
</script>
