<template lang="pug">
.radio-btn-group.text-center
  .radio(v-for="option in options")
    input(type="radio", :name="name", :value="option.value", @input="changedValue", :id="option.value")
    label(:for="option.value") {{option.name}}
</template>
<script>
export default {
  name: "RadioButton",
  props: {
    name: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      checked: ""
    };
  },
  methods: {
    changedValue: function(event) {
      this.checked = event.target.value;
      this.$emit("changeValue", this.checked);
    }
  }
};
</script>
<style lang="scss">
@import "src/assets/scss/variables";

.radio-btn-group {
  .radio {
    margin: 0 0.25rem;
    display: inline-block;
    label {
      background: #fff;
      border: 1px solid #ddd;
      padding: 1.25rem;
      border-radius: 5px;
      cursor: pointer;
      color: #444;
      transition: box-shadow 400ms ease;
      &:hover {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1),
          0 2px 10px 0 rgba(0, 0, 0, 0.12);
      }
    }
  }
  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + label {
    background: $primary;
    color: #fff;
    border-color: $primary;
  }
}
</style>
