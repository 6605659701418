<template lang="pug">
#plans
  h2.text-center.py-2 Precios para que su trabajo sea efectivo
  h6.text-center.text-muted Ofrecemos 100% satisfacción y garantía de venta de tus vehículos.
  b-row#prices.justify-content-md-center.mb-5.mt-3
    b-col(cols="3").mt-5
      b-card
        .circle
        h5.mt-3.mb-1.font-weight-medium.text-center.title-white PARTICULAR
        h6.subtitle.font-weight-normal.text-center.my-2.subtitle-white Tiempo ilimitado.
        .pricing.my-3
          sup $
          span.yearly.display-5 0
          small.yearly CLP
        p.text-muted.text-center Máximo 7 publicaciones
        p.text-muted.text-center Máximo 10 imagenes por vehículo
        p 
          br
        b-button(variant="outline-mhmotors" block) Obtener
    b-col(cols="3").mt-3
      b-card(style="height: 424px")
        .circle
        h5.mt-3.mb-1.font-weight-medium.text-center.title-white PROFESIONAL
        h6.subtitle.font-weight-normal.text-center.my-2.subtitle-white Tiempo 1 Mes.
        .pricing.my-3
          sup $
          span.yearly.display-5 22.000
          small.yearly CLP
        p.text-muted.text-center Sin limite publicaciones
        p.text-muted.text-center Máximo 20 imagenes por vehículo
        b-button(variant="mhmotors" block) Obtener
    b-col(cols="3").mt-5
      b-card
        .circle
        h5.mt-3.mb-1.font-weight-medium.text-center.title-white  PROFESIONAL
        h6.subtitle.font-weight-normal.subtitle-white.text-center.my-2 Tiempo 1 Año.
        .pricing.my-3
          sup $
          span.yearly.display-5 250.800
          small.yearly CLP
        p.text-muted.text-center Sin limite publicaciones
        p.text-muted.text-center Máximo 20 imagenes por vehículo
        p.text-muted.text-center 5% de descuento por pago anual
        b-button(variant="mhmotors" block) Obtener
</template>
<script>
export default {
  name:"ProfesionalPlans"
}
</script>
<style lang="scss">
  @import "@/assets/scss/_variables";
  #plans{
    .pricing {
      text-align: center;
      color: #8d97ad;
      .display-5 {
        font-size: 2.6rem;
        color: #263238;
      }
      sup {
        font-size: 18px;
        top: -20px;
      }
    }
    .circle{
      background-color: $primary;
      height: 600px;
      width: 600px;
      top: -500px;
      right: -175px;
      position: absolute;
      border-radius: 50%;
      z-index: 2;
    }
    #prices{
      .card {
        .title-white{
          color:white;
          text-shadow: 0 0 5px rgba(0,0,0,.2)
        }
        .subtitle-white{
          color:#ffddcb;
          text-shadow: 0 0 5px rgba(0,0,0,.2)
        }
        h5,h6 {
          z-index: 3;
          position: relative;
        }
        &-body {
          height: 390px;
          position: relative;
        }
        .btn {
          position: absolute;
          bottom: 20px;
          left: 0;
          right: 0;
          margin:0 auto;
          width: 85%;
        }
      }
    }
  }
</style>